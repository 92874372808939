// Imports
var ___HTML_LOADER_IMPORT_0___ = new URL("/static/img/currency/bitcoin/bitcoin_account_white.png", import.meta.url);
var ___HTML_LOADER_IMPORT_1___ = new URL("/static/img/bank_account_white.png", import.meta.url);
var ___HTML_LOADER_IMPORT_2___ = new URL("/static/img/info.png", import.meta.url);
var ___HTML_LOADER_IMPORT_3___ = new URL("/static/img/user-circle.svg", import.meta.url);
// Module
var code = `<template>
  <require from="./payments.scss"></require>
  <require from="components/card-banka/card-banka"></require>
  <require from="components/card-banka-empty/card-banka-empty"></require>
  <require from="components/date-format-date-only"></require>
  <require from="components/transaction-component/transaction-component"></require>
  <require from="components/card-banka-detailled/card-banka-detailled"></require>
  <require from="components/beneficiary/beneficiary"></require>
  <require from="components/ValueConverters/currency-denomination"></require>
  <require from="components/number-format"></require>
  <require from="components/ValueConverters/iban-converter"></require>
  <require from="components/number-format-eur"></require>
  <require from="components/number-format-bsv"></require>
  <require from="components/loader-ring/loader-ring"></require>
  <require from="components/header-titles/header-title-payments/header-title-payments"></require>
  <require from="components/ValueConverters/currency-formatter/currency-formatter"></require>

  <section id="payments" class="flex-column">
    <header-title-payments title.bind="pageTitle"></header-title-payments>
    <div class="from flex-column">
      <div class="from_change flex-row">
        <h2 t="bank.payment.make_payments1"></h2>
      </div>
      <div id="payment-sender-listing" class="listing">
        <div id="payment-sender-div" class="object flex-row" click.delegate="goToChooseWallet()" if.bind="state.makePayment.sender">
          <img src="${___HTML_LOADER_IMPORT_0___}" if.bind="state.makePayment.sender.isBlockchain"/>
          <img src="${___HTML_LOADER_IMPORT_1___}" if.bind="!state.makePayment.sender.isBlockchain"/>
          <loader-ring if.bind="loader"></loader-ring>
          <div class="texte flex-column" if.bind="!loader">
            <p class="title">\${ state.makePayment.sender.name }</p>
            <p class="desc"   if.bind="state.makePayment.sender.isBlockchain">  \${ state.makePayment.sender.identification }</p>
            <p class="desc"   if.bind="!state.makePayment.sender.isBlockchain">\${ state.makePayment.sender.identification | ibanConverter}</p>
            <p class="desc" if.bind="state.makePayment.sender.isBlockchain">
              \${state.makePayment.sender.balance  | numberFormatBsv:localeForCurrency}
            </p>
            <p class="desc" if.bind="!state.makePayment.sender.isBlockchain">
              \${state.makePayment.sender.balance | numberFormatEur:localeForCurrency
              }\${state.makePayment.sender.currency | currencyDenomination }
            </p>
          </div>
        </div>
        <div id="payment-sender-div" class="object flex-row" click.delegate="goToChooseWallet()" if.bind="!state.makePayment.sender">
          <img src="${___HTML_LOADER_IMPORT_2___}"/>
          <div class="texte flex-column" if.bind="!loader">
            <p class="title" t="payments.no_selected_account"></p>
            <p class="desc" t="payments.add_one"></p>
          </div>
          <div class="flex-grow"></div>
        </div>
      </div>
    </div>
    <div class="to flex-column">
      <div class="to_change flex-row">
        <h2 t="home.to"></h2>
        <a t="home.changePaymail" click.delegate="isPaymail = !isPaymail" if.bind="!locked && !isPaymail">Change to Paymail</a>
        <a t="home.changeContact" click.delegate="isPaymail = !isPaymail" if.bind="!locked && isPaymail && !paymail">Change to Contact</a>
      </div>
      <div id="payment-receiver-listing"  class="listing">
        <div id="payment-receiver-div"  if.bind="!state.makePayment.recipient && !isPaymail" class="object flex-row" click.delegate="goToContacts()">
          <img src="${___HTML_LOADER_IMPORT_3___}" />
          <div class="texte flex-column">
            <p class="title"><span t="bitcoin.payment.make_payment_select_recipient_bitcoin"></span></p>
            <p class="desc"></p>
          </div>
          <div class="flex-grow"></div>
        </div>
        <div id="payment-receiver-div-not_locked" if.bind="state.makePayment.recipient && !locked && !isPaymail" class="object flex-row" click.delegate="goToContacts()">
          <img src="${___HTML_LOADER_IMPORT_0___}" if.bind="state.makePayment.recipient.identification.includes('@')"/>
          <img src="${___HTML_LOADER_IMPORT_1___}" if.bind="!state.makePayment.recipient.identification.includes('@')"/>
          <div class="texte flex-column">
            <p class="title"><span>\${state.makePayment.recipient.name}</span></p>
            <p class="desc">\${state.makePayment.recipient.identification}</p>
          </div>
          <div class="flex-grow"></div>
        </div>
        <div id="payment-receiver-div-locked" if.bind="state.makePayment.recipient && locked && !isPaymail" class="object flex-row">
          <img src="${___HTML_LOADER_IMPORT_0___}" if.bind="state.makePayment.recipient.identification.includes('@')"/>
          <img src="${___HTML_LOADER_IMPORT_1___}" if.bind="!state.makePayment.recipient.identification.includes('@')"/>
          <div class="texte flex-column">
            <p class="title"><span>\${state.makePayment.recipient.name}</span></p>
            <p class="desc">\${state.makePayment.recipient.identification}</p>
          </div>
          <div class="flex-grow"></div>
        </div>
      </div>
    </div>
    <form class="inputs column" id="paymentInfo" submit.delegate="goToReviewPayments()">
      <div class="object flex-row" if.bind="isPaymail && !locked">
        <input id="paymail" value.bind="paymail" type="text" placeholder="Paymail" required/>
      </div>
      <div class="no_border" if.bind="!locked">
        <currency-formatter if.bind="!locked" currency.bind="state.makePayment.sender.currency"
        amount.two-way="amount" amount-formatted.two-way="amountFormated"        
        required/>
        <currency-formatter currency.bind="infoPayment.currency"
        amount.two-way="infoPayment.amount" amount-formatted.two-way="amountFormated"        
        required/>
      </div>
      <div if.bind="locked">
        <input id="amount" value.bind="infoPayment.amount"  type="number" i18n="[placeholder]bank.payment.amount.tabtitle"   readonly/>
      </div>
      <div>
        <input id="comment" value.bind="comment" type="string" i18n="[placeholder]bank.payment.comment.tabtitle" if.bind="!locked"/>
        <input id="comment" value.bind="comment" type="string" i18n="[placeholder]bank.payment.comment.tabtitle" if.bind="locked" readonly/>
      </div>
    </form>
    <div class="flex-grow" if.bind="displayError"></div>
    <span class="error" if.bind="displayError">\${errorMessage}</span>
    <a class="error" if.bind="errorLink" click.delegate="router.navigateToRoute('bitcoin_import')">Import Wallet</a>
    </div>
    <div class="flex-grow"></div>
    <button id="recap-buttn" click.delegate="goToReviewPayments(state.makePayment.sender.identification)" disabled.bind="!(amount > 0) && !paymail || !(amount > 0) && !state.makePayment.recipient" class="btn btn-primary" form="paymentInfo">
      <span i18n="bank.payment.recap.tabtitle" if.bind="!isCreatingPayment"></span>
      <loader-ring class="loader-btn" if.bind="isCreatingPayment"></loader-ring>
    </button>
    <div class="spacer-default"></div>
  </section>
</template>
`;
// Exports
export default code;